import { PromoCodeStatus } from '@/controllers/graphql/generated';
import { PromoCodeLandingFragment } from '@/controllers/promoCode/generated/promoCodeLanding.fragment.generated';

export interface RegistrationQuery {
  courseSlug?: string;
  redirectTo?: string;
  studentSource?: string;
  selectedSlug?: string;
  success_redirect_url?: string;
}

export const ExpiredStatuses = [
  PromoCodeStatus.Expired,
  PromoCodeStatus.Inactive,
];

export interface SuccessPageProps {
  courseSlug?: string;
  shouldRedirectToTrialIntro?: boolean;
  shouldRedirectToIntroductionTopic?: boolean;
  moduleSlug?: string;
  topicSlug?: string;
  redirectUrl?: string;
}

export interface TimerExpiredAtTimeOptions {
  promoCode: PromoCodeLandingFragment;
  promoFirstInteractionTime: number;
}

export interface ConsultationRedirectLinkProps {
  courseSlug?: string;
  shouldRedirectToTrialIntro?: boolean;
  subscriptionPlanUuid?: string;
  shouldRedirectToIntroductionTopic?: boolean;
  moduleSlug?: string;
  topicSlug?: string;
  redirectUrl?: string;
}
